import React from 'react';
import { viewports } from '../../style-vars';

const { smOrSmaller } = viewports;

const GuyFormStyles = () => (
  <style jsx global>{`
    .guy-form {
      margin-top: 7.5%;
      margin-bottom: 10%;
    }

    .guy-form-cont {
      padding: 7.5% 0 !important;
      margin-top: 3%;
      width: 100%;
      background-size: contain !important;
      background-position: center center !important;
      background-repeat: no-repeat !important;
    }

    .guy-form form {
      display: table;
      margin: 0 auto 64px auto !important;
      width: 50%;
      background: #fff !important;
    }

    @media ${smOrSmaller} {
      .guy-form {
        padding: 32px;
      }

      .guy-form h1,
      .guy-form p {
        text-align: left !important;
      }

      .guy-form div {
        justify-content: left !important;
      }
    }

    @media @md-or-smaller {
      .guy-form form {
        width: 100%;
      }
    }
  `}</style>
);

export default GuyFormStyles;
