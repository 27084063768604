import React from "react";
import loadable from "@loadable/component";
import Layout from "../components/Layout/layout";
import SEO from "../components/seo";
import ogImage from "../images/global_assets/og-image.png";
import Hero from "../components/Hero/hero";
import { guyHero, guyTemplate, guyTrusted } from "../data/landing/guy";
import { advisoryBoard } from "../data/restaurant-data";
// import TrustedNumbers from "../components/TrustedNumbers/TrustedNumbers";
// import TestmonialReviews from "../components/TestimonialReviews/TestmonialReviews";
import { autoOverviewTestimonials } from "../data/auto-overview-data";
import data from "../data/forms/lead-form";
import { extractDefaultValues } from "../utils/form-utils";
import { lineOfBusiness } from "../constants";
import LeadForm from "../components/Forms/LeadForm";
import Heading from "../components-v2/Base/HeadingBuilder";
import bgImage from "../images/hero-images/guy-form-bg.png";
import GuyFormStyles from "../components/CustomForms/GuyFormStyles";
// import PartnerLogos from "../components/PartnerLogos/PartnerLogos";
// import UspFeatures from "../components/ConversionPath/UspFeatures";
// import Badges from "../components/ConversionPath/Badges";
// import GuyForm from "../components/CustomForms/GuyForm";
// import WebinarStuff from "../components/SpecialtyComps/WebinarStuff";

const AdvisoryBoard = loadable(() =>
  import("../components/PosSection/PosSection")
);

const TrustedNumbers = loadable(() =>
  import("../components/TrustedNumbers/TrustedNumbers")
);
const TestmonialReviews = loadable(() =>
  import("../components/TestimonialReviews/TestmonialReviews")
);
const PartnerLogos = loadable(() =>
  import("../components/PartnerLogos/PartnerLogos")
);
const UspFeatures = loadable(() =>
  import("../components/ConversionPath/UspFeatures")
);
const Badges = loadable(() => import("../components/ConversionPath/Badges"));
const GuyForm = loadable(() => import("../components/CustomForms/GuyForm"));
const WebinarStuff = loadable(() =>
  import("../components/SpecialtyComps/WebinarStuff")
);

const GuyFieri = () => {
  return (
    <Layout>
      <SEO
        title="Guy Fieri | Reboot"
        description="Keeping up with trends and providing your customers with
          the service they expect at an affordable price is essential to remain
          competitive in the automotive industry. SpotOn’s custom-tailored point-of-sale
          and digital tools will help you boost business efficiency and improve customer retention."
        image={`https://spoton.com/${ogImage}`}
      />
      <Hero sectionData={guyHero} />
      <WebinarStuff />
      <section style={{ marginBottom: 60 }}>
        <AdvisoryBoard sectionData={advisoryBoard} />
      </section>
      <TrustedNumbers title="By the numbers" numbersArray={guyTrusted} />
      {/* <GuyForm */}
      {/*  id="get-a-demo" */}
      {/*  leadType="lead-gen" */}
      {/*  formId="4b826830-c69c-40f7-99aa-7ea1e2a7a4cd" */}
      {/*  thankYouPath="/lp/thank-you" */}
      {/* /> */}

      <div style={{ background: `url(${bgImage})` }} className="guy-form-cont">
        <Heading
          className="text-black text-center"
          level={2}
          injectionType={2}
          withBlueSymbol
        >
          Book your consultation
        </Heading>
        <LeadForm
          formName="Lead Form"
          noHeader
          displayForm
          formData={data}
          initialValues={extractDefaultValues(data)}
          header=""
          subHeader=""
          onValuesChange={() => true}
          useFormValueAsDataLayerEventName
          formValueForDataLayerEventName="industry"
          dataLayerEventNameAppendix="-lead"
          withLineOfBusinessOption={false}
          lineOfBusiness={lineOfBusiness.HOSPITALITY}
          thankYouPath="/lp/thank-you"
          leadType="lead-gen"
        />
      </div>
      <Badges />
      <section style={{ marginTop: "5%" }}>
        <UspFeatures
          data={guyTemplate}
          lineA="Powerful tech"
          lineB="Customized for your restaurant"
        />
      </section>
      <section style={{ marginBottom: "3%" }}>
        <PartnerLogos />
      </section>
      <TestmonialReviews sectionData={autoOverviewTestimonials} />
      <section style={{ marginBottom: "3%" }}>
        <PartnerLogos />
      </section>

      <GuyFormStyles />
    </Layout>
  );
};

export default GuyFieri;
