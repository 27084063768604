/* eslint-disable import/prefer-default-export */

/* eslint-disable no-useless-concat */
// eslint-disable-next-line import/prefer-default-export
export const guyHero = {
  title:
    "Empowering restaurants with powerful, integrated tech and 5-star service",
  subtext:
    "As a proud sponsor of Guy’s Restaurant Reboot, SpotOn is providing a $25,000 grant and a free best-in-class point-of-sale system to one lucky restaurateur. But you don’t need to win a contest to get the technology you deserve. SpotOn helps local restaurants every day with solutions to boost efficiency, connect with guests, and grow revenue.",
  // heroImg: "guy.png",
  fatTitle: "Reboot",
  AnchorCta: {
    linkTo: "/guy#get-a-demo",
    ctaTitle: "Learn more",
  },
  video: {
    videoBtn: "Watch video",
    posterWrapperClass: "",
    posterImg: "guy-video-poster.png",
    videoUrlId: "QxS1mcE0pU4",
  },
};
// export const guyHomeHero = {
//   title: " Grow your business with someone you can trust",
//   subtext:
//     "The world changes every five minutes, and your business needs the right technology to keep up with your customers’ expectations—in person, online, and on the go. We can help.",
//   imageClassName: "fixed-height-600 top-adjustement",
//   AnchorCta: {
//     linkTo: "/guy#get-a-demo",
//     ctaTitle: "Learn more",
//   },
//   video: {
//     videoBtn: "Watch video",
//     posterWrapperClass: "",
//     posterImg: "Home_video_thumb.png",
//     videoUrlId: "8QVvWcGplAw",
//   },
// };
export const guyTrusted = [
  {
    title: "400+",
    subtext: "Product innovations in 2020",
  },
  {
    title: "$1.5m+",
    subtext: "Software fees waived during the pandemic",
  },
  {
    title: "300m+",
    subtext: "Guest marketing campaigns deployed",
  },
  {
    title: "101m+",
    subtext: "Reservations served using SpotOn Reserve",
  },
];

export const guyLargeFeatureA = {
  featureBlocks: [
    {
      blockTitle: "Tune in to hear from restaurant industry leaders",
      blockSubTitle:
        "Watch Guy’s Restaurant Reboot to get expert insights on the future of the restaurant industry from some of America’s most notable chef’s, restaurateurs, and industry leaders, including members of SpotOn’s Restaurant Advisory Council.",
      blockImg: "Guy.png",
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Lean More",
        ctaTarget: "/guy",
      },
    },
  ],
};

export const guyTemplate = [
  {
    content: "Lightning-fast front-of-house",
    desc: "Streamline operations, accelerate growth, and provide guests with a five-star experience, in-house and online.",
    img: "web-access.png",
  },
  {
    content: "Cloud-based back-of-house",
    desc: "Manage employee schedules, menus, table layouts, pricing events, and more—from anywhere.",
    img: "cloud-based.png",
  },
  {
    content: "Online ordering",
    desc: "Offer commission-free online ordering directly to your customers for take-out, delivery, and dine-in.",
    img: "online-ordering.png",
  },
  {
    content: "Handheld POS",
    desc: "Enhance your guest experience and turn more tables with easy-to-use handhelds, part of our fully scalable hardware options.",
    img: "terminal-pos.png",
  },
  {
    content: "Elite guest data & reporting",
    desc: "Make smarter decisions with 90+ real-time reports, automated manager alerts and subscriptions, and enterprise reporting.",
    img: "reporting.png",
  },
  {
    content: "Seamless integration",
    desc: "Combine SpotOn with the software you already know and use, or integrate SpotOn’s intuitive software solutions for one easy-to-use system.",
    img: "seemless.png",
  },
];
